.asideListing {
    border-radius: 5px;
     display: block;
     padding: 1rem;
     background-color: #E6F2D8;
}
.asideListing li {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
   transition: all 0.2ms;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    fill: #2C2C2C;
    color: #2C2C2C;
    background-color: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    padding: 20px 20px 20px 20px;
    margin-bottom: 1rem;
}
.asideListing li svg {
    margin-left: 0.5rem;
}
.asideListing li:hover, .asideListing li.active, .asideListing li:focus, .asideListing li:active {
    background-color: #53a92c;
    color: #fff;
}


@media screen and (min-width: 768px) {
    .sectionWrap {
        padding: 1.5em;
    }
}

@media screen and (max-width: 767px) {
    .mobOrder {
        order: 1;
    }

}
