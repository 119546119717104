.barHead {
    border-left: 19px solid #138a2c;
    margin-bottom: 16px;
    padding: 10px 0 10px 10px;
    color: #138a2c;
    font-size: 40px;
    line-height: 46px;
    -moz-hyphens: none;
    word-wrap: normal;
    margin-top: 30px;
}

@media screen and (max-width: 767px) {
    .barHead {
        font-size: 30px;
        line-height: 32px;
        border-left: 5px solid #138a2c;
    }
}